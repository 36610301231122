.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family : inherit;
  font-size: 1em;
  outline: none;
  border: none !important;
  background-color: transparent;
  color: whitesmoke;
  align-items: center;
}
ul{
  color: white;
}
p{
  color: white;
}

.card-title {
  color: white;
}
a {   
  color: white;   
}

a:active {
  color: blue;
}

a[tabindex]:focus {
  color:blue;
  outline: none;
}
.navbar-btn{
  font-size:xx-large
}

.bioText{
  font-size: 2rem;
}
.card-title{
  font-size: xx-large;
}
.card-links{
  font-size: medium;
}
.card-text{
  font-size: large;
} 
.bio{
  border-color: white;
  border-style: solid;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35em

}
.link-text{
  font-size: xx-large 
}
